<template>
  <div class="part_menu">
 
<ul>
  <li class="bg3"><router-link @click.native="scrollToTop" to="/mypage/">マイページ・トップ</router-link></li>
  <li class="bg2"><router-link @click.native="scrollToTop" to="/mypage/order_history">ご注文履歴・もう一度注文</router-link></li>
  <!--<li class="bg3">お届け先リストからお手紙注文</li>-->
  <li class="bg4"><router-link @click.native="scrollToTop" to="/mypage/deliver">お届け先の登録・編集</router-link></li>
  <li class="bg5"><router-link @click.native="scrollToTop" to="/mypage/profile">ご注文者様情報の登録・編集</router-link></li>
  <li class="bg6"><a @click.prevent="logout">ログアウト</a></li>
</ul>

  </div>
</template>

<script>
export default  {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.part_menu{
  padding: 0 1em;
}
ul{
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: .9em;
  margin: 2em 0;
  justify-content: center;
  align-items: center;
  li{
    flex-grow: 1;
    padding: .8em 0;
    margin-bottom: 1em;
    max-width: 600px;
    width: 100%;
    text-align: left;
    background: #D72E23;
    border-radius: .3em;
    a{
    padding: .5em 0;
    border-radius: .3em;
      display: block;
      color: #fff;
      text-decoration: none;
    padding-left: 4.5em;
    }
    &.bg1 a{
      background:  url("../assets/images/mypage1.png") no-repeat 1em center / contain;
    }
    &.bg2 a{
      background:  url("../assets/images/mypage1.png") no-repeat 1em center / contain;
    }
    &.bg3 a{
      background:  url("../assets/images/mypage2.png") no-repeat 1em center / contain;
    }
    &.bg4 a{
      background:  url("../assets/images/mypage3.png") no-repeat 1em center / contain;
    }
    &.bg5 a{
      background:  url("../assets/images/mypage4.png") no-repeat 1em center / contain;
    }
    &.bg6 a{
      background:  url("../assets/images/mypage5.png") no-repeat 1em center / contain;
    }
    a.router-link-exact-active{
      color: rgba(255, 255, 255, 0.534);
    }
  }
}
</style>
