<template>
  <div class="inline_error" v-if="message">
    <div class="errormini">▲
        {{message}}
      
    </div>
  </div>
</template>
      
<script>
export default {
    props:["name","errors"],
    computed:{
      message:function(){
        for(var i=0;i<this.errors.length;i++){
          //console.log(this.errors[i].id+"=="+'fields_'+this.name)
          if(this.errors[i].id=='field_'+this.name){
            return this.errors[i].msg;
          }
        }
        return ""
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .errormini {
    background-color: #FEF47A;
    color: #FF0000;
    font-size: 75%;
    font-weight: bold;
    line-height: 1.4;
    list-style-type: none;
    margin-top: 5px;
    padding: 5px;
}
</style>